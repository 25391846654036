var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("FICHA DE TRATAMIENTO ORTODONCIA")]),_c('table',{staticClass:"table table-bordered table-sm"},[_vm._m(0),_c('tbody',[_vm._l((_vm.items),function(l){return _c('tr',{key:l.id,class:{
          'table-danger': l.quote_status_id == 6 || l.quote_status_id == 10,
          'table-warning': l.quote_status_id == 9
        }},[_c('td',[_vm._v(_vm._s(l.created_at))]),_c('td',[_vm._v(_vm._s(l.actions_maked))]),_c('td',[_vm._v(_vm._s(l.next_target))]),_c('td')])}),_c('tr',[_c('td',{staticClass:"p-0",staticStyle:{"width":"120px"}}),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.actions_maked),expression:"actions_maked"}],staticClass:"editable",attrs:{"type":"text","placeholder":"Cita actual"},domProps:{"value":(_vm.actions_maked)},on:{"input":function($event){if($event.target.composing){ return; }_vm.actions_maked=$event.target.value}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.next_target),expression:"next_target"}],staticClass:"editable",attrs:{"type":"text","placeholder":"Proxima cita"},domProps:{"value":(_vm.next_target)},on:{"input":function($event){if($event.target.composing){ return; }_vm.next_target=$event.target.value}}})]),_c('td',[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){_vm.$refs.dQuote.show();
              _vm.$refs.quoteForm.reset();
              _vm.$refs.quoteForm.setEmployeeId(_vm.plan.employee_id);
              _vm.quote_time = null;}}},[_c('i',{staticClass:"fa fa-calendar"})]),_c('app-button-submit',{staticClass:"btn-sm",on:{"click":function($event){return _vm.save(1)}}},[_c('i',{staticClass:"fa fa-save"})])],1)])],2)]),_c('app-modal-basic',{ref:"dQuote",attrs:{"xl":true}},[_c('h4',[_vm._v("CITA DE ORTODONCIA")]),_c('QuoteForm',{directives:[{name:"show",rawName:"v-show",value:(!_vm.quote_time),expression:"!quote_time"}],ref:"quoteForm",on:{"timeSelected":function($event){_vm.$refs.elMiniForm.setIniTime($event, _vm.plan.client_id, _vm.next_target);
        _vm.quote_time = $event;}}}),_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.quote_time),expression:"quote_time"}],staticClass:"col-md-4 col"},[_c('miniForm',{ref:"elMiniForm",attrs:{"employee_id":_vm.plan.employee_id,"interval":10},on:{"submitted":function($event){_vm.$refs.dQuote.hide();
            _vm.save(1, $event);}}})],1)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center table-info",attrs:{"colspan":"4"}},[_vm._v(" EVOLUCION DE TRATAMIENTO ")])]),_c('tr',[_c('th',[_vm._v("FECHA")]),_c('th',[_vm._v("DESCRIPCION")]),_c('th',[_vm._v("PROXIMO TRATAMIENTO")]),_c('th')])])}]

export { render, staticRenderFns }