<template>
  <div>
    <h4>FICHA DE TRATAMIENTO ORTODONCIA</h4>
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th class="text-center table-info" colspan="4">
            EVOLUCION DE TRATAMIENTO
          </th>
        </tr>
        <tr>
          <th>FECHA</th>
          <th>DESCRIPCION</th>
          <th>PROXIMO TRATAMIENTO</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="l in items"
          :key="l.id"
          :class="{
            'table-danger': l.quote_status_id == 6 || l.quote_status_id == 10,
            'table-warning': l.quote_status_id == 9
          }"
        >
          <td>{{ l.created_at }}</td>
          <td>{{ l.actions_maked }}</td>
          <td>{{ l.next_target }}</td>
          <td></td>
        </tr>
        <tr>
          <td style="width: 120px" class="p-0">
            <!-- <app-datepicker v-model="date"></app-datepicker> -->
          </td>
          <td>
            <input
              type="text"
              class="editable"
              v-model="actions_maked"
              placeholder="Cita actual"
            />
          </td>
          <td>
            <input
              type="text"
              class="editable"
              v-model="next_target"
              placeholder="Proxima cita"
            />
          </td>
          <td>
            <button
              class="btn btn-primary btn-sm"
              @click="
                $refs.dQuote.show();
                $refs.quoteForm.reset();
                $refs.quoteForm.setEmployeeId(plan.employee_id);
                quote_time = null;
              "
            >
              <i class="fa fa-calendar"></i>
            </button>
            <app-button-submit @click="save(1)" class="btn-sm">
              <i class="fa fa-save"></i>
            </app-button-submit>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <h5>Comentarios</h5>
    <app-comments class="mb-3" :url="`program_quotes_ort_${treatment_plan_id}`"></app-comments> -->

    <app-modal-basic ref="dQuote" :xl="true">
      <h4>CITA DE ORTODONCIA</h4>
      <QuoteForm
        @timeSelected="
          $refs.elMiniForm.setIniTime($event, plan.client_id, next_target);
          quote_time = $event;
        "
        ref="quoteForm"
        v-show="!quote_time"
      ></QuoteForm>
      <div class="row">
        <div v-show="quote_time" class="col-md-4 col">
          <miniForm
            :employee_id="plan.employee_id"
            :interval="10"
            ref="elMiniForm"
            @submitted="
              $refs.dQuote.hide();
              save(1, $event);
            "
          ></miniForm>
        </div>
      </div>
    </app-modal-basic>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import QuoteForm from "../quotes/Form";
import { myMoment } from "../../utils/myMoment";
import miniForm from "../quotes/mini-form";
// import SelectDuration from "../quotes/select-duration";

export default {
  components: {
    QuoteForm,
    miniForm
    // SelectDuration
  },

  //directives
  //filters

  props: {
    client_id: {},
    treatment_plan_id: {}
  },

  data: () => ({
    plan: {},
    items: [],
    // date: null,
    actions_maked: null,
    next_target: null,
    quote_id: null,
    quote_time: ""
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    DentalClinicService.getTreatmentPlan(this.treatment_plan_id).then(
      ({ plan }) => {
        this.plan = plan;
      }
    );
    this.getDetails();
  },

  methods: {
    // saveQuote() {
    //   DentalClinicService.saveQuote({}).then(quote_id => {
    //     this.quote_id = quote_id;
    //   });
    // },
    getDetails() {
      DentalClinicService.getProgramQuotes({
        treatment_plan_id: this.treatment_plan_id
      }).then(res => {
        this.items = res.map(x => {
          x.created_at = myMoment(x.created_at).format("YYYY-MM-DD");
          return x;
        });
      });
    },
    resetForm() {
      this.actions_maked = null;
      this.next_target = null;
      this.quote_id = null;
    },
    save(group, quote_id) {
      DentalClinicService.saveProgramQuote({
        actions_maked: this.actions_maked,
        next_target: this.next_target,
        next_quote_id: quote_id,
        treatment_plan_id: this.treatment_plan_id,
        client_id: this.client_id,
        group
      }).then(res => {
        this.resetForm();
        this.items.push(res);
      });
    }
  }
};
</script>

<style></style>