<template>
  <select v-model="valueChild" class="custom-select" @focus="getNextBusyTime()">
    <option
      v-for="(l, i) in Array(maxDuration / interval)"
      :key="i"
      :value="(i + 1) * interval"
      v-show="(i + 1) * interval <= maxDuration"
    >
      {{ (i + 1) * interval }} minutos
    </option>
  </select>
</template>
<script>
// import http from "../../http";
import { DentalClinicService } from "../service";
import { myMoment, momentDateTimeFormat } from "../../utils/myMoment";
import _ from "lodash";

export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    value: {},
    interval: {},
    iniTime: {},
    employeeId: {},
    quoteId: {}
  },

  data: () => ({
    maxDuration: 0,
    group: 1
  }),

  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },

  watch: {
    employeeId() {
      this.getNextBusyTime();
    },
    iniTime() {
      this.getNextBusyTime();
    }
  },

  mounted() {
    //
  },

  methods: {
    setGroup(val) {
      this.group = val;
    },
    getGroup() {
      return this.group;
    },
    getNextBusyTime() {
      this.asyncGetNextBusyTime(this);
    },
    asyncGetNextBusyTime: _.debounce((vm) => {
      if (vm.iniTime && vm.employeeId) {
        DentalClinicService.getScheduleEmployeeBusyTime({
          // datetime: "2019-09-11 16:30",
          // employeeId: 21
          datetime: myMoment(vm.iniTime).format(momentDateTimeFormat),
          employeeId: vm.employeeId,
          quote_id: vm.quoteId,
          group: vm.group
        }).then((nextBusyTime) => {
          if (nextBusyTime == "") {
            if (myMoment(vm.iniTime).hour() < 13) {
              nextBusyTime = "13:00:00";
            } else {
              nextBusyTime = "21:00:00";
            }
          }
          let timeToStart = myMoment(vm.iniTime);
          let busyHour = nextBusyTime.split(":")[0];
          let busyMinute = nextBusyTime.split(":")[1];
          let maxTime = myMoment(vm.iniTime);
          maxTime.hour(busyHour);
          maxTime.minute(busyMinute);

          // console.log(maxTime.format(momentDateTimeFormat));
          // console.log();
          vm.maxDuration = parseInt(maxTime.diff(timeToStart, "minute"));
        });
      }
    }, 300)
  }
};
</script>

<style></style>
